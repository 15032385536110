import { useEffect, useRef, useState } from "react";

import logoHeader from "assets/images/ccr-logo-home.svg";

import { IcoArrowDown, IcoClose, IcoMenu, IcoSearch } from "assets/icons";
import colors from "colors";
import { Button, DownloadMenu } from "components";
import { m } from "framer-motion";
import { ErrorHelper, StringHelper } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LocationService } from "services/location";
import { RootState } from "store";
import { setLocations as setLocationsRedux } from "store/features/locations/slice";
import SearchPage from "./modules/SearchPage";
import SideMenu from "./modules/SideMenu";
import "./styles.scss";

export default function Header() {
	const navigate = useNavigate();
	const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
	const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState<boolean>(false);
	const [isLocationMenuOpen, setIsLocationMenuOpen] = useState<boolean>(false);
	const [isSearchPageOpen, setIsSearchPageOpen] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>("");
	const [searchTextSubmitted, setSearchTextSubmitted] = useState<string>("");
	const { locations } = useSelector((state: RootState) => state.location);
	const sortedLocations = [...locations].sort((a, b) =>
		a.name.localeCompare(b.name)
	);
	const routerLocation = useLocation();

	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
	const menuRef = useRef<HTMLDivElement>(null);
	const locationService = new LocationService();
	const dispatch = useDispatch();

	const handleToggleSideMenuOpen = () => {
		if (!isSideMenuOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setIsSideMenuOpen(!isSideMenuOpen);
	};

	const handleDownloadMenuToggle = () => {
		if (!isDownloadMenuOpen) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setIsDownloadMenuOpen(!isDownloadMenuOpen);
	};

	const handleSearchPageToggle = () => {
		if (!isSearchPageOpen) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setIsSearchPageOpen(!isSearchPageOpen);
	};

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsLocationMenuOpen(false);
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		locationService
			.listLocations()
			.then((response) => {
				dispatch(setLocationsRedux(response));
			})
			.catch((error) => {
				toast.error(ErrorHelper.getErrorMessage(error));
			});
	}, []);

	useEffect(() => {
		document.body.style.overflow = "auto";
		setIsSearchPageOpen(false);
	}, [routerLocation]);

	const handleSearchSubmit = (e?: React.FormEvent) => {
		if (e) e.preventDefault();
		setSearchTextSubmitted(searchText);
		const hasSearch = searchText.length > 0;
		if (hasSearch) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setIsSearchPageOpen(hasSearch);
	};

	const logoVariants = {
		show: {
			x: 0,
			transition: {
				ease: "easeOut",
				duration: 0.3
			}
		},
		hide: {
			x: "-100%"
		}
	};

	const desktopSearchBar = (
		<form
			className="flex items-center justify-between h-10 grow mr-8 max-w-lg border px-4 border-neutral-high-100 rounded-3xl"
			onSubmit={handleSearchSubmit}
		>
			<input
				type="search"
				name="search"
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				placeholder="Busque aqui"
				className="focus-visible:outline-0 grow"
			/>
			{isSearchPageOpen ? (
				<div
					onClick={() => {
						setSearchText("");
						setSearchTextSubmitted("");
						handleSearchPageToggle();
					}}
				>
					<IcoClose />
				</div>
			) : (
				<div onClick={handleSearchSubmit} className="cursor-pointer">
					<IcoSearch size="24" />
				</div>
			)}
		</form>
	);

	const mobileSearchBar = (
		<>
			{isSearchPageOpen && (
				<form
					className="flex items-center justify-between h-16 absolute left-0 top-0 px-4 w-screen"
					onSubmit={handleSearchSubmit}
				>
					<m.input
						type="text"
						name="search"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder="Busque aqui"
						className="focus-visible:outline-0 grow"
						initial={{ y: "-100%", opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.2, delay: 0.2 }}
					/>
					<m.div
						initial={{ y: "-100%", opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.2, delay: 0.3 }}
						onClick={() => {
							setSearchText("");
							setSearchTextSubmitted("");
							handleSearchPageToggle();
						}}
					>
						<IcoClose size="24" />
					</m.div>
				</form>
			)}
			<div
				onClick={handleSearchPageToggle}
				className={`transition-all duration-300 ${
					isSearchPageOpen ? "translate-x-16" : ""
				}`}
			>
				<IcoSearch size="24" />
			</div>
		</>
	);

	return (
		<header className="header">
			<div className="wrapper-general">
				<m.div
					variants={logoVariants}
					animate={isSearchPageOpen && isMobile ? "hide" : "show"}
					className="grow"
				>
					{isMobile && (
						<div className="hamburger-icon" onClick={handleToggleSideMenuOpen}>
							<IcoMenu color={colors.neutral["low-pure-500"]} />
						</div>
					)}
					<div className="logo-wrapper">
						<Link to="/">
							<img src={logoHeader} alt="logo-icon" className="logo" />
						</Link>
					</div>
					{!isMobile && desktopSearchBar}
				</m.div>
				{isMobile ? (
					mobileSearchBar
				) : (
					<nav>
						<div ref={menuRef}>
							<Button
								cssClass="menu-toggle nav-button border-none"
								onClick={() => setIsLocationMenuOpen(!isLocationMenuOpen)}
								styled="secondary"
								textBase
							>
								<div
									className={`arrow-icon ${isLocationMenuOpen ? "active" : ""}`}
								>
									<IcoArrowDown
										size="24"
										color={colors.neutral["low-pure-500"]}
									/>
								</div>
								Localidades
							</Button>
							{isLocationMenuOpen && (
								<div className="location-list">
									{sortedLocations?.map((location) => (
										<Link
											key={`location-${location.id}`}
											to={`localidade/${StringHelper.slugify(location.name)}`}
											onClick={() => setIsLocationMenuOpen(false)}
										>
											{location.name}
										</Link>
									))}
								</div>
							)}
						</div>
						<Button
							onClick={() => navigate("/parceiro")}
							cssClass="nav-button border-none"
							styled="secondary"
							textBase
						>
							Seja um parceiro
						</Button>
						<Button size="medium" textBase onClick={handleDownloadMenuToggle}>
							Baixar aplicativo
						</Button>
					</nav>
				)}
			</div>
			<SideMenu
				onClose={handleToggleSideMenuOpen}
				locations={sortedLocations}
				isVisible={isSideMenuOpen}
			/>
			<DownloadMenu
				onClose={handleDownloadMenuToggle}
				isVisible={isDownloadMenuOpen}
			/>
			<SearchPage
				onClose={handleSearchPageToggle}
				isVisible={isSearchPageOpen}
				searchText={searchTextSubmitted}
				onSearch={handleSearchSubmit}
			/>
		</header>
	);
}
