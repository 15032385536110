import { Benefit } from "models/Benefit";
import { BaseServices } from "./baseServices";

export class BenefitService extends BaseServices<Benefit> {
	constructor() {
		super(Benefit);
	}

	async listHighlightedBenefits() {
		return this.listPaginated(`public/benefits/highlighted`, {
			page: 1,
			size: 10,
			status: 1
		});
	}

	async listBenefits(location: string, categoriesIds: string[]) {
		return this.listPaginated(`public/benefits`, {
			categoriesIds,
			citiesIds: location,
			status: 1
		});
	}

	async listBenefitsByPartner(partnerId: any, archived?: boolean) {
		return super.get(`backoffice/benefits/partner/${partnerId}`, { archived });
	}

	async searchBenefits(search: string, page: number) {
		return this.listPaginated(`public/benefits`, {
			storeName: search,
			size: 10,
			page,
			status: 1
		});
	}

	async getBackofficeDetails(id: any) {
		return super.get(`backoffice/benefits/${id}`);
	}

	async listCategories() {
		return this.get(`public/categories`);
	}

	async createBenefit(data: any) {
		return super.create("backoffice/benefits", data);
	}

	async createBenefitThroughProspect(data: any) {
		data.benefitValidationUrl = `${window.location.protocol}//${window.location.host}/backoffice/partners/${data.partnerRegisterId}/`;
		return super.create(`user/benefits`, data);
	}

	async activateBenefit(id: string) {
		return super.patch(`backoffice/benefits/${id}`, null);
	}

	async deactivateBenefit(id: string) {
		return super.delete(`backoffice/benefits/${id}`, null);
	}

	async archiveBenefit(benefitId: string, partnerId: string) {
		return super.patch(
			`backoffice/benefits/${benefitId}/partner/${partnerId}/archive`,
			null
		);
	}

	async restoreBenefit(benefitId: string, partnerId: string) {
		return super.patch(
			`backoffice/benefits/${benefitId}/partner/${partnerId}/restore`,
			null
		);
	}
}
