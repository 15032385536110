import IcoLoadingNeutral from "assets/icons/ico-loading-neutral.png";
import IcoLoadingPrimary from "assets/icons/ico-loading-primary.png";
import IcoLoadingSecundary from "assets/icons/ico-loading-secondary.png";
import { motion } from "framer-motion";
import { LoadingProps } from "./types";

import "./styles.scss";

export default function Loading({ type, size = 24 }: LoadingProps) {
	let image;

	switch (type) {
		case "primary":
			image = IcoLoadingPrimary;
			break;
		case "secondary":
			image = IcoLoadingSecundary;
			break;
		default:
			image = IcoLoadingNeutral;
			break;
	}

	return (
		<motion.div
			animate={{ rotate: 360 }}
			transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
			style={{ maxHeight: `${size}px`, maxWidth: `${size}px` }}
			className="container-loading"
		>
			<img src={image} width={size} height={size} alt="Carregando..." />
		</motion.div>
	);
}
